<template>
  <div>
    <b-form @submit.prevent="handleSubmitCredentials()">
      <b-form-group label="CNPJ">
        <b-form-input
          placeholder="00.000.000/0000-00"
          v-model.trim="$v.cnpj.$model"
          v-mask="'##.###.###/####-##'"
          :class="$v.cnpj.$error && 'is-invalid'"
          @input="handleGetCnpj"
        ></b-form-input>
        <b-form-invalid-feedback v-if="!$v.cnpj.required">
          Digite um CNPJ válido
        </b-form-invalid-feedback>
      </b-form-group>
      <section class="details" v-if="details && details.nome">
        <hr />
        <h5>Dados do cliente</h5>
        <p>
          Nome: <strong> {{ details.nome || "---" }}</strong>
        </p>
        <b-form-group label="Telefone">
          <b-input v-model="details.telefone"></b-input>
          <b-form-invalid-feedback>
            Não é o e-mail do cadastro do CNPJ
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="E-mail">
          <b-input v-model="details.email"></b-input>
        </b-form-group>

        <p>
          Natureza jurídica:
          <strong>{{ details.natureza_juridica || "---" }}</strong>
        </p>
        <p>{{ details.tipo || "---" }} - {{ details.uf || "---" }}</p>
        <div class="d-flex justify-content-end">
          <b-button variant="link" @click="$emit('submited')">Cancelar</b-button>
          <b-button
            type="submit"
            variant="primary"
            :disabled="isLoading"
            class="pull-right text-right"
          >
            <b-spinner v-if="isLoading" small class="mr-2" />
            <span> {{ $t("general.save") }}</span>
          </b-button>
      </div>
      </section>
      
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { getCnpj } from "@/utils/validations/getCnpj.js";
import _ from "lodash";

export default {
  mixins: [validationMixin],
  props: {
    id: {
      type: [String, Number],
    },
    isAdd: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: false,
      cnpj: null,
      admin: false,
      details: {},
    };
  },
  validations: {
    cnpj: {
      required,
      minLength: minLength(14),
    },
  },
  computed: {
    ...mapGetters(["user", "is_preview", "preview_personify"]),
  },
  methods: {
    ...mapActions(["add_provider_client", "get_provider_client", "show_toast"]),
    handleSubmitCredentials() {
      this.isLoading = true;
      if (!this.$v.$anyError && !this.$v.$anyError && this.cnpj?.length >= 14) {
        this.$v.$touch();
        this.saveUser();
      } else {
        this.isLoading = false;
        this.show_toast({
          message:
            "Certifique-se de que este campo tenha mais de 14 caracteres.",
          type: "error",
        });
      }
    },
    handleGetCnpj: _.debounce(async function(cnpj) {
      this.details = await getCnpj(cnpj);
    }, 500),

    saveUser() {
      const payload = {
        cnpj: this.cnpj.replace(/[^\d]/g, ""),
        email: this.details.email,
        phone: this.details.telefone.replace(/[^\d]/g, ""),
      };
      this.add_provider_client(payload)
        .then(({ status }) => {
          if ([200, 201].includes(status)) {
            this.show_toast({
              message: "Cadastro realizado com sucesso",
              type: "success",
            });
            this.$emit("submited");
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.show_toast({
            message: err,
            type: "error",
          });
        });
    },

    getEdit() {
      this.get_provider_client({ id: this.id }).then(({ data }) => {
        this.cnpj = data.cnpj;
      });
    },
  },

  mounted() {
    if (!this.isAdd) {
      this.getEdit();
    }
  },
};
</script>

<style scoped lang="scss">
.form-data {
  max-width: 600px;
  margin: 0 auto;
}
</style>
