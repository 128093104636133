<template>
  <div>
    <div>
      <section class="mb-3">
        <small class="d-block mb-3 d-flex align-items-center">
          <b-icon class="h4 mr-2" icon="exclamation-circle"></b-icon>
          Adicionar o contato dos 5 (cinco) maiores clientes (com maior faturamento) da sua empresa. Será enviado uma
          pesquisa de satisfação para cada um deles. O retorno dessa pesquisa e parte importante do processo de analise da
          sua empresa.
        </small>
        <section class="d-flex justify-content-between align-items-center">

          <div class="input-search col-md-5 p-0">
            <b-form-input @input="handleSearch" v-model="search" placeholder="Digite para pesquisar" class="mb-2" />

            <b-button variant="light" class="px-2 d-flex align-items-center absolute icon-action">
              <b-icon icon="search"></b-icon>
            </b-button>
          </div>

          <b-button class="btn-add" @click="handleAddNewPosition()">
            Adicionar
          </b-button>
        </section>
      </section>
      <skeleton height="198px" grid="4" gap="10" :rows="10" v-if="isBusy" />
      <cards-list grid="4">
        <b-card no-body class="px-3 py-2 mb-2 mr-2" v-for="({ social_reason, cnpj, email, id }, index) in users"
          :key="index">
          <div class="d-flex flex-row justify-content-between w-100" style="height: 50px">
            <strong class="flex-1 text-primary">{{ social_reason }}</strong>
            <div class="my-md-0 my-2 ml-4">
              <b-icon icon="trash" class="h6 pointer mt-1 text-small icon-action" @click="handleSelectDelete(id)" />
            </div>
          </div>
          <hr />
          <span class="flex-1 my-md-0 my-2">
            CNPJ: {{ cnpj | VMask("##.###.###/####-##") }}
          </span>
          <span class="flex-1 my-md-0 my-2"> E-email: {{ email }} </span>
        </b-card>
      </cards-list>
      <empty-list v-if="users && users.length === 0 && !isBusy" :size="70" text="Nenhuma informação encontrada">
      </empty-list>
    </div>
    <b-modal id="add-employee" centered hide-footer size="md" :title="`${isAdd ? 'Adicionar cliente' : `Editar ${this.selectedEmployee.name}`
      }`
      ">
      <Form :id="selectedId" :isAdd="isAdd" @submited="handleCloseModal" />
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import Form from "./form.vue";

export default {
  components: {
    Form,
  },
  data() {
    return {
      isAdd: false,
      isBusy: false,
      legal_personnel: "",
      selectedEmployee: { id: "" },
      selectedId: null,
      search: "",
      users: [],
    };
  },
  computed: {
    ...mapGetters(["user", "is_provider"]),
  },
  methods: {
    ...mapActions([
      "get_all_provider_clients",
      "delete_provider_client",
      "show_delete",
    ]),
    getListProviderClients() {
      this.isBusy = true;
      const params = {
        provider: this.user?.enterprise?.id,
        search: this.search,
        type: this.type,
      };

      if (this.search.length === 0) {
        delete params.search;
      }

      if (this.type === "0") {
        delete params.type;
      }

      this.get_all_provider_clients({ ...params })
        .then(({ data }) => {
          this.users = data.results;
          this.isBusy = false;
        })
        .finally(() => (this.isBusy = false));
    },

    handleAddNewPosition() {
      this.isAdd = true;
      this.selectedId = this.selectedEmployee.id;
      this.$bvModal.show("add-employee");
    },
    handleCloseModal() {
      this.$bvModal.hide("add-employee");
      this.getListProviderClients();
    },
    handleEditPosition(id) {
      this.isAdd = false;
      this.selectedId = id;
      this.$bvModal.show("add-employee");
    },

    handleSelectDelete(id) {
      this.selectedId = id;
      this.show_delete({
        in_delete: true,
      });
    },

    handleSearch: _.debounce(function (value) {
      this.search = value;
      this.getListProviderClients();
    }, 500),
  },
  mounted() {
    this.getListProviderClients();
    this.$root.$on("confirm-close-delete", (isDelete) => {
      if (isDelete) {
        this.delete_provider_client(this.selectedId).then(() => {
          this.getListProviderClients();
          this.show_delete({
            in_delete: false,
          });
        });
      }
    });
  },
};
</script>
